import { Container, Content } from 'components/layout';
import { Box } from 'components/layout/Box';
import { Header } from 'components/layout/Header';
import { NoteDrawerArgs } from 'components/notes/NoteDrawer';
import { BasicNote, NotesTable } from 'components/notes/NotesTable';
import { DrawerContext } from 'context/DrawerContext';
import { useQryProject } from 'graphql/generated';
import { FC, MouseEvent, useContext, useMemo, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { RouteProps } from 'types/route';
import { NoteDeleteDialog } from '../../../components/NoteDeleteDialog';
import { format } from 'date-fns';

import { useTranslation } from 'react-i18next';
import { Button } from 'components/buttons';
import { MdOutlineFileDownload, MdOutlinePostAdd } from 'react-icons/md';
import useIsMobile from 'hooks/useIsMobile';
import { isEmpty } from 'lodash';
import { useNoteContext } from 'context/NoteContext';
import { unparse } from 'papaparse';
import { downloadFile } from 'utils/download';
import { parseHtml } from 'dataParsing/util';

type Props = RouteComponentProps<RouteProps>;

const ProjectNotes: FC<Props> = ({ match }) => {
  const { t } = useTranslation();
  const [selectedNotes, setSelectedNotes] = useState<Set<string>>(new Set());

  const projectId = match.params.projectId;
  const [{ data: project, fetching: loading }, refetchProjects] = useQryProject(
    {
      id: true,
      name: true,
      notes: {
        id: true,
        title: true,
        description: true,
        period: {
          start: true,
          end: true,
        },
        owner: {
          id: true,
          email: true,
          firstName: true,
          lastName: true,
          createdAt: true,
        },
        views: {
          id: true,
          name: true,
          type: true,
        },
        locations: {
          id: true,
          name: true,
          updatedAt: true,
          createdAt: true,
          organization: {
            id: true,
            name: true,
            createdAt: true,
            email: true,
            timezone: true,
            users: {
              id: true,
              email: true,
              firstName: true,
              lastName: true,
              createdAt: true,
            },
          },
        },
        updatedAt: true,
        createdAt: true,
      },
    },
    { uuid: projectId },
    { pause: isEmpty(projectId) },
  );
  const { drawerRequest } = useContext(DrawerContext);
  const notes = useMemo(() => project?.notes ?? [], [project]);
  const [attemptDeleteNote, setAttemptDeleteNote] = useState<BasicNote>();

  const selectedNoteId = useMemo(() => {
    if (drawerRequest?.drawerId === 'note') {
      return (drawerRequest.args as NoteDrawerArgs).noteId;
    }
  }, [drawerRequest]);

  const handleOndDeleteNote = () => {
    refetchProjects();
    setAttemptDeleteNote(undefined);
  };

  const { requestDrawer } = useContext(DrawerContext);
  const { resetNoteState } = useNoteContext();
  const isMobile = useIsMobile();

  const onDownloadSelectedNotes = (e?: MouseEvent, note?: BasicNote) => {
    const notesToDownload = note ? [note] : notes.filter((note) => selectedNotes.has(note.id));
    const formattedData = notesToDownload.map((note) => ({
      [t('Title')]: note.title,
      [t('Description')]: parseHtml(note.description ?? ''),
      [t('From')]: note.period?.start ? format(new Date(note.period.start), 'yyyy-MM-dd HH:mm') : '-',
      [t('To')]: note.period?.end ? format(new Date(note.period.end), 'yyyy-MM-dd HH:mm') : '-',
      [t('Created')]: format(new Date(note.createdAt), 'yyyy-MM-dd HH:mm'),
      [t('Author')]: `${note.owner.firstName} ${note.owner.lastName}`,
    }));

    const csv = unparse(formattedData, { quotes: true });

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    downloadFile(blob, `${project?.name}.csv`);
  };

  const handleOnSelectNote = (note: BasicNote) => {
    resetNoteState(note, note.locations);
    requestDrawer('note', {
      noteId: note.id,
      listArgs: {
        period: note.period,
        locations: note.locations,
        title: note.title,
        description: note.description,
      },
    });
  };

  return (
    <Container backgroundColor="note.100">
      {project && (
        <Header
          variant="notes"
          title={t('Notes')}
          controls={
            <>
              {isMobile && (
                <Button variant="note" onClick={() => requestDrawer('note')}>
                  <MdOutlinePostAdd />
                </Button>
              )}
              {selectedNotes.size > 0 && (
                <Button smallPad variant="plain" onClick={onDownloadSelectedNotes} iconLeft={MdOutlineFileDownload}>
                  {t('actions.Download')}
                </Button>
              )}
            </>
          }
        />
      )}
      <Content loading={loading}>
        <Box flexGrow={1} overflowY="auto" height={128} paddingBottom={40}>
          {project?.notes && (
            <NotesTable
              notes={notes}
              onSelectNote={handleOnSelectNote}
              onDeleteNote={setAttemptDeleteNote}
              selectedNoteId={selectedNoteId}
              setSelectedNotes={setSelectedNotes}
              selectedNotes={selectedNotes}
              onDownloadSelectedNotes={onDownloadSelectedNotes}
            />
          )}
        </Box>
      </Content>
      {attemptDeleteNote && (
        <NoteDeleteDialog
          note={attemptDeleteNote}
          onCancel={() => setAttemptDeleteNote(undefined)}
          onAfterDelete={handleOndDeleteNote}
        />
      )}
    </Container>
  );
};

export default ProjectNotes;
