export const parseNumber = (value: string): number | Error => {
  // eslint-disable-next-line no-self-compare
  if (+value === +value) {
    return +value;
  }
  return new TypeError(`The provided string is not a number, received: ${value}`);
};

export const parseHtml = (html: string) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || '';
};
